export default {
    "error.500": "Hi ha hagut un error intern en el sistema",
    "error.404": "No s'ha trobat la pàgina",
    "error.403": "No tens els permisos necessaris per efectuar aquesta acció",
    "error.1001": "La persona no té accés a l'aplicació. Crea un usuari si vols veure i gestionar permisos",
    "error.1002": "Permís desconegut",
    "error.1003": "Usuari no vàlid",
    "error.5001": "El codi ha de ser únic",
    "error.5002": "La posició ha ser única",
    "error.other": "Hi ha hagut un error",
    "error.differentArea": "El departament no coincideix",
    "error.subjectNameInUse": "El nom de la matèria està en ús",
    "error.subjectCodeInUse": "El codi de la matèria està en ús",
    "error.save": "No s'han pogut desar els canvis",
    "error.teacherRoleNotExist": "El rol no existeix",
    "error.invalidDniFormat": "El DNI no té un format vàlid",
    "error.invalidNifFormat": "El NIF no té un format vàlid",
    "error.invalidZipFormat": "El codi postal no té un format vàlid",
    "error.contactFormat": "El contacte no té un format vàlid",
    "error.contactPersonNotFound": "No s'ha trobat la persona a la qual es vol associar el contacte",
    "error.contactOwnedByOtherPerson": "El contacte és de {person}",
    "error.contactDuplicated": "Contacte duplicat",
    "error.personWithIdNotFound": "No s'ha trobat la persona amb l'ID especificat",
    "error.dniUsedByAnotherPerson": "El DNI pertany a un altre persona. {person}",
    "error.nifUsedByAnotherPerson": "El NIF pertany a un altre persona. {person}",
    "error.niaUsedByAnotherPerson": "El NIA pertany a un altre persona. {person}",
    "error.groupCourseStageNotExist": "El grup no existeix",
    "error.person.personRole.min": "La persona ha de pertanyer com a mínim a un grup del sistema",
    "invalid_grant": "Combinació usuari clau de pas incorrectes",
    "error.userPassword": "Combinació usuari clau de pas incorrectes",
    "error.passwordMissMatch": "Les contrasenyes han de coincidir",
    "error.fieldMandatory": " El camp {field} és obligatori",
    "error.startDateBiggerThanEndDate": "Data inici major que data fi",
    "error.codeExists": "Codi ja utilitzat",
    "error.nameExists": "Nom ja utilitzat",
    "error.correct": "Si us plau, corregeix els errors",
    "error.user.add.pwd.required": "Es necessari indicar la contrasenya d'accés a l'aplicació per poder crear l'usuari",
    "error.wrongFormat": "Format incorrecte",
    "error.fieldCanNotBeEmpty": "El camp {field} no pot estar buit",
    "error.groupCourseStageNotEmpty": " El {group} no està buit. Cal eliminar els alumnes i/o les programacións associades",
    "error.noDelete": "No s'ha pogut eliminar el contingut",
    "error.code_coursestage_idExists": "El codi ja existeix a aquest curs",
    "error.fieldIsMandatory": "El camp és obligatori",
    "error.contacts.update": " al desar la informació dels contactes",
    "error.parents.update": " al desar la informació dels pares/tutors o altres persones relacionades amb l'alumne",
    "error.parents.deleted": " al esborrar algun dels pares de l'alumne",
    "error.contacts.deleted": " al esborrar el contacte",
    "error.Notenoughfields": "No s'han trobat els camps mínims requerits",
    "error.emptyfile": "No hi ha cap registre",
    "error.reviewWrongFields": "Revisi els camps marcats amb error",
    "error.notValidExtension": "Extensió no vàlida",
    "error.center_id_nameExists": "Ja existeix un element amb aquest nom",
    "error.center_id_codeExists": "Ja existeix un element amb aquest codi",
    "error.name_stage_idExists": "Nom en ús",
    "error.code_stage_idExists": "Codi en ús",
    "error.tagNotExists": "El tag no eisteix",
    "error.categoryNotExists": "La categoria no existeix",
    "error.noTeachers": "iEduca no disposa de professors",
    "error.coursestage_id_subject_idExists": "La programació ja està definida. Canvia la matèria i/o el tipus",
    "error.tagGroupNotExists": "La categoria no existeix",
    "error.delete": "No ha sigut possible eliminar l'element seleccionat",
    "error.MustChooseOneCenter": " Ha d'escollir un Centre",
    "error.entityNotFound": "No s'ha trobat cap element amb l'id indicat",
    "error.CourseStageHaveGroups": " El nivell conté cursos. S'han de treure primer",
    "error.templateCanNotBeDisabled": "La plantilla no es pot desactivar, ja que és l'única activa per aquesta funcionalitat",
    "error.canNotDeleteTemplateBecauseOnlyActive": "No es pot esborrar la plantilla ja que és l'única activa per aquesta funcionalitat",
    "error.templateNotExist": "La plantilla no existeix",
    "error.personDniNotExist": "No existeix cap persona amb aquest DNI",
    "error.personIdNotExist": "No existeix cap persona amb aquest ID",
    "error.usernameUsedByAnotherPerson": "El nom d'usuari pertany a un altre persona",
    "error.passwordTooShort": "La contrasenya és massa curta",
    "error.personIdentifierNotSpecified": "No s'ha especificat cap identificador per la persona",
    "error.idIsNotAnInteger": "L'ID ha de ser un número",
    "error.noSelected": "No ha seleccionat cap element",
    "error.WrongCode": " Codi erroni",
    "error.contactAlreadyAssignedToThisPerson": "La persona ja té aquest contacte",
    "error.contactOwnsOtherPerson": "El contacte pertany a una altre persona",
    "error.PersonNoPermissionsOrAccess": "La persona no té permisos o usuari per accedir",
    "error.codeNotValid": "Codi no vàlid",
    "error.nameNotValid": "Nom no vàlid",
    "error.nameNotValidLength": "La longitud del nom supera el límit de caracters permés",
    "error.notSaved": "No ha sigut possible guardar els canvis",
    "error.notSelected": "No ha seleccionat cap opció",
    "error.RoleNotExists": "El role no existeix",
    "error.body": "No s'ha pogut guardar",
    "error.enrolmentNumInUse": "El número de matrícula ja existeix",
    "error.firstMarkType": "Per afegir observacions, primer necessita seleccionar un tipus de falta",
    "error.load": "No ha sigut possible carregar la informació",
    "error.yearInUse": "Aquest any s'està utilitzant en més d'un centre, per tant, només es pot activar o desactivar, per editar la resta de camps, vagi a la vista global",
    "error.IdIsNotNumber": "El camp ha de ser numèric",
    "error.removeGroup": "No s'ha pogut eliminar el grup",
    "error.enrolmentsExists": "No és possible esborrar aquesta programació perquè actualment hi ha alumnat matriculat. Elimina prèviament les matrícules vinculades",
    "error.templateNameAlreadyExistsInGlobal": "Ja hi ha una plantilla amb el mateix nom en la gestió global",
    "error.NumberOfAffectationIsBiggerThan1": "No és pot passar a local si afecta a més d'un centre",
    "error.NumberOfAffectationIsEqualTo0": "No és pot passar a local perquè no afecta a cap centre",
    "error.templateNameAlreadyExistsInCenter": "Ja hi ha una plantilla amb el mateix nom en aquest centre",
    "error.programNotExists": "La programació no existeix",
    "error.nameSurname1InUse": "Ja existeix una persona amb el mateix nom i cognom",
    "error.evaluationPeriodHasEvaluables": "El període d\'evaluació no es pot esborrar perquè té avaluables associats",
    "error.FinalEvaluableForEvaluationAndGroupAlreadyExists": "Ja existeix un avaluable final per aquest grup i període d\'evaluació",
    "error.fileNotSaved": "No s'ha pogut guardar el fitxer",
    "error.canNotCreateFolder": "No s'ha pogut crear la carpeta",
    "error.loadUserFolder": "No ha sigut possible carregar el directori personal",
    "error.routeNotValid": "La ruta especificada no és vàlida",
    "error.folderExists": "La carpeta ja existeix",
    "error.fileNotFound": "El fitxer no existeix",
    "error.OnlyOneEvaluableWithConvocatoryForProgramAllowed": "Aquesta programació ja té un avaluable per aquesta convocatoria",
    "error.personMustHaveOneSystemGroup": "La persona ha de tenir almenys un rol del sistema",
    "error.key_nameExists": "El nom ja existeix",
    "error.CanNotRemoveGlobalEntity": "No és possible eliminar l'element perquè té dependències",
    "error.NoPermissionToCreateEnrolment": "No tens permisos per matricular alumnes",
    "error.operationNotAllowed": "Operació no vàlida",
    "error.name_stage_id_center_idExists": "Aquest nom ja existeix",
    "error.SubjectDoesntExist": "La matería no existeix",
    "error.GroupDoesntExist": "El grup no existeix",
    "error.PersonDoesntExist": "La persona no existeix",
    "error.kcIdNotValid": "K&C ID no vàlid",
    "error.templateCanNotBeSaved": "La plantilla no es pot desar, ja que existeixen col·lisions entre etiquetes de diferents grups",
    "error.canNotDeleteSystemTemplate": "Les plantilles de sistema no poden ser eliminades",
    "error.convocatoryHasEvaluables": "La convocatoria té evaluables associats",
    "error.fieldContainsNotAllowedCharacters": "El camp conté caràcters especials no permesos. (Permesos: lletres, números, punts, guions i guions baixos)",
    "error.sendDay.future": "Aquesta data d'enviament no és vàlida",
    "error.sendHour.future": "Aquesta hora d'enviament no és vàlida",
    "error.program_id_competence_idExists": "La dimensió ja està afegida a aquesta programació",
    "error.nameMandatory": "El nom és obligatori",
    "error.enrolmentsNotExists": "No hi ha alumnes matriculats",
    "error.groupIsNotEmpty": "No es pot esborrar el grup perquè té membres associats",
    "error.notExistCourseStage": "El nivell/curs no existeixen",
    "error.emptyText": "El text està buit",
    "error.notExistClassContent": "El contingut no existeix",
    "error.evaluationNotExists": "L'avaluació no existeix",
    "error.CompetenceHasGrades": "No es pot esborrar la competència perquè té notes posades en aquesta programació",
    "error.AmbitCannotBeEmpty": "És obligatori que una dimensió pertanyi a un ambit",
    "error.CantDeleteNotOwnedCompetences": "No es pot esborrar aquesta competència perquè pertany a una altre persona",
    "error.DimensionMustBeEmptyToDelete": "Per esborrar la dimensió, primer cal esborrar totes les competències que li pertanyen",
    "error.studentNotEnrolled": "L'alumne no està matriculat",
    "error.textCannotBeEmpty": "El text no pot estar buit",
    "error.code_center_idExists": "Ja existeix un element amb aquest codi",
    "error.name_center_idExists": "Ja existeix un element amb aquest nom",
    "error.noTemplateDefinedForThisCourse": "No hi ha cap plantilla definida per aquest curs",
    "error.coursestage_id_functionality_idExists": "Ja existeix una plantilla definida d'aquesta funcionalitat per aquest curs",
    "error.enrolment_id_program_idExists": "Ja existeix una matricula per aquest alumne, en el mateix grup i amb les mateixes matèries",
    "error.PreinscriptionEmailInUse": "Ja existex un usuari amb l'email indicat en la plataforma",
    "error.PreinscriptionEmailAlreadyRegistered": "Ja existeix un usuari amb l'email indicat en aquesta preinscripció",
    "error.PreinscriptionPhoneInUse": "Ja existex un usuari amb el telèfon indicat en la plataforma",
    "error.PreinscriptionPhoneAlreadyRegistered": "Ja existeix un usuari amb el telèfon indicat en aquesta preinscripció",
    "error.FamiliarMandatory": "Si l'alumne és menor d'edat, és obligatori afegir un tutor",
    "error.preRegistrationNotOpen": "La preinscripció està tancada",
    "error.nameOrKeyNameInUse": "El nom ja existeix",
    "error.hour_ini_hour_endExists": "La franja horària ja existeix",
    "error.AmbitMustBeEmptyToDelete": "Per esborrar l'àmbit ha d'estar buit",
    "Stage no esta buit": "No es pot eliminar aquest nivell perquè conté cursos. Cal esborrar-los prèviament",
    "error.CourseStageNotEmpty": "No es pot eliminar el curs {curs} perquè conté grups. Cal esborrar-los prèviament",
    "error.cannotSaveProgramContent": "No s'ha pogut guardar la planificació",
    "programContentDeleted": "S'ha esborrat la planificació",
    "error.name_group_idExists": "Ja existeix un element amb aquest nom per aquest grup",
    "error.gradeValueNotValid": "El valor introduït no és vàlid per aquest mètode d'avaluació",
    "error.classeCompetenceNotExists": "La competència no està vinculada en aquesta sessió i no es pot eliminar",
    "error.classeCompetenceIdNotValid": "L'id de la competència d'aquesta sessió no és vàlid",
    "error.competenceIdNotValid": "L'id de la competència no és vàlid",
    "error.competenceNotExists": "No existeix aquesta competència",
    "error.classeNotValid": "Aquesta sessió no és vàlida",
    "error.repetitionEventNotDragable": "Aquest esdeveniment no pot ser arrossegat per tractar-se d'un esdeveniment amb repetició, si us plau, indica el que voleu fer a continuació",
    "error.PhoneIsMandatory": "El telèfon és obligatori",
    "error.MailIsMandatory": "El correu electrònic és obligatori",
    "error.programCodeNotExist": "No existeix cap programació amb aquest/s codi/s: ",
    "error.noTemplatesAvailable": "No hi ha cap plantilla disponible per imprimir",
    "error.enrolmentWithSamePropertiesExists": "L'alumne ja està matriculat en aquest grup",
    "error.classeNotInRequest": "Classe no vàlida",
    "error.classeNotExists": "La classe no existeix",
    "error.removeProgramEnrolmentExists": "La programació té matrícules associades",
    "error.removeProgramClasseExists": "La programació té classes associades",
    "error.removeProgramCompetenceExists": "La programació té competències associades",
    "error.removeProgramContentExists": "La programació té contingut associat",
    "error.subjectCannotBeNull": "L'assignatura no pot estar buida",
    "error.studentIsInClass": "L'alumne ja pertany a aquesta classe",
    "error.classe_id_session_id_competence_idExists": "Aquest criteri ja està associat a la sessió actual",
    "error.commerceGlobalStageLocal": "No és possible desar els canvis perquè el Comerç ja existeix al centre local",
    "error.commerceLocalStageGlobal": "No és possible desar els canvis perquè el Comerç depèn d'un registre local",
    "error.commerceStageDifferentCenter": "No és possible desar els canvis perquè els centres no coincideixen",
    "error.cantActivateIfAvailabilityHasNoGroup": "No es pot activar aquesta preinscripció perquè té disponibilitats sense cap grup assignat",
    "error.fileExists": "Aquest fitxer ja existeix",
    "error.subject_id_coursestage_idExists": "La programació ja existeix per aquest curs",
    "error.startDateFormatNotValid": "El format de la data inicial és invalid",
    "error.programContentNotExists": "No existeix aquest contingut",
    "error.classeContentNotExistsOrDeleted": "Aquest contingut ha sigut esborrat o no existeix",
    "error.cannotDeleteProgramContent": "No es pot esborrar el contingut",
    "error.programContentNotInRequest": "Contingut no vàlid",
    "error.functionalityNotDefined": "Aquest curs no té cap funcionalitat definida per poder executar aquesta acció",
    "error.timetableWithoutChanges": "No s'han detectat canvis en l'horari",
    "error.frequencyCannotBeNull": "El camp frecuencia no pot ser buit",
    "error.evaluableHasGrades": "L'avaluable té notes",
    "error.evaluableNoPermission": "No tens permisos per modificar aquest avaluable",
    "error.classe_id_session_id_document_idExists": "Ja està inserit aquest document en aquesta sessió",
    "error.competenceLinkedToClasses": "La competència pot estar enllaçada a una classe",
    "error.gradeNotExists": "La nota no existeix",
    "error.ProgramIdCannotBeNull": "L'identificador de la matèria no pot estar buit",
    "error.GroupIdCannotBeNull": "L'identificador del grup no pot estar buit",
    "error.studentCanNotViewGradesOfOtherStudent": "L'estudiant no pot veure les notes d'un altre estudiant",
    "error.parentCanNotViewGradesOfOtherStudent": "No es pot veure les notes d'un altre estudiant que no sigui familiar",
    "error.stageCoordinatorCanNotViewGradesOfOtherStudent": "No es pot veure les notes d'un estudiant que no pertany a un nivell del que ets coordinador",
    "error.studentIsNotFromGroupOfEvaluable": "L'estudiant no està al grup de l'avaluable",
    "error.teacherIsNotInProgram": "El professor no imparteix aquesta matèria en aquesta classe",
    "error.canNotViewGradesOfThisGroup": "No tens permís per veure les notes d'aquest grup",
    "error.CantEditNotOwnedCompetences": "No es poden modificar les competències que no són pròpies",
    "error.CompetenceDoesntExist": "La competència no existeix",
    "error.AmbitDoesntExist": "L'àmbit no existeix",
    "error.ProgramIdCantBeNull": "L'identificador de la matèria no pot estar buit",
    "error.ProgramDoesntExist": "La matèria no existeix",
    "error.evaluationPeriodIdNotValid": "L'identificador del període no és vàlid",
    "error.evaluableIdsNotValid": "Els identificadors dels avaluables no són vàlids",
    "error.studentCantBeNull": "L'estudiant no pot estar buit",
    "error.subjectCantBeNull": "La matèria no pot estar buida",
    "error.studentIdCannotBeNull": "L'identificador de l'estudiant no pot estar buit",
    "error.studentNotFound": "No s'ha trobat l'estudiant",
    "error.courseStageIdCannotBeNull": "L'identificador del curs no pot estar buit",
    "error.courseStageNotFound": "No s'ha trobat el curs",
    "error.evaluationPeriodIdCannotBeNull": "L'identificador del període no pot estar buit",
    "error.evaluationPeriodNotFound": "No s'ha trobat el període",
    "error.groupNotFound": "No s'ha trobat el grup",
    "error.programNotFound": "No s'ha trobat la matèria",
    "error.FunctionalityNoFound": "No s'ha trobat la funcionalitat",
    "error.personNotExists": "La persona no existeix",
    "error.personIdNotValid": "L'identificador de la persona no és vàlid",
    "error.programIdNotValid": "L'identificador de la matèria no és vàlid",
    "error.evalCompetEvalPerNotExists": "L'avaluable, la competència o el període d'avaluació no existeixen",
    "error.evalIdCompetIdEvalPerIdNotValid": "Els identificadors de l'avaluable, de la competència o del període d'avaluació no són vàlids",
    "error.valueNotValid": "El valor no és vàlid",
    "error.commentGradeNotExists": "El comentari de la nota no existeix, ja s'ha esborrat.",
    "error.commentGradeIdNotValid": "L'identificador del comentari de la nota no és vàlid",
    "validate.contact.format": "Valida el format del contacte",
    "error.valueExists": "El valor ja existeix",
    "error.enrolment.not_exist": "No existeix aquesta matrícula",
    "error.groupRelatedNotFound": "No s'ha trobat el grup",
    "error.groupIdNotValid": "L'identificador de grup no és vàlid",
    "error.studentsIdsNotValid": "L'identificador de l'alumne no és vàlid",
    "error.noCommentsSaved": "No s'ha pogut guardar el comentari",
    "error.noAllCommentsSaved": "No s'han pogut guardar els comentaris",
    "error.subjectNotExists": "L'assignatura no existeix.",
    "error.name_group_id_program_idExists": "El nom ja existeix per aquesta grup.",
    "error.courseStageOrSubjectNotValid": "Nivell, Curs i Matèria són obligatoris per la categoria seleccionada.",
    "error.reportNotDefined": "Aquest curs no té un informe predefinit.",
    "error.subjectHaveProgram": "La matèria té programacions vinculades.",
    "error.subjectHaveChildren": "La matèria té fills.",
    "error.commentIsObligatory": "És obligatori posar un comentari.",
    "error.PermissionDenied": "No té permís.",
    "error.children.update": "Error en actualitzar els fills d'aquest usuari.",
    "error.groupNotExists": "No existeix el grup.",
    "error.subjectIdNotValid": "L'id de l'assignatura no és vàlid.",
    "error.courseStageNotExists": "No existeix el curs.",
    "error.courseStageIdNotValid": "L'id del curs no és vàlid.",
    "error.sessionNotExists": "No existeix la sessió.",
    "error.fileNameNotValid": "El nom del fitxer no és vàlid.",
    "error.documentNotFound": "No s'ha trobat el document.",
    "error.tokappNotFound": "No s'ha trobat cap usuari de Tokapp amb aquest contacte.",
    "error.person_id_evaluable_id_convocatory_idExists": "És un valor duplicat.",
    "error.enrolmentAlreadyExists": "Ja existeix una matricula d'aquest alumne en aquest grup. Només es pot matricular més d'un cop en un grup individual.",
    "error.commentDefaultNotFound": "El comentari predefinit no existeix.",
    "error.commentDefaultCanNotSave": "El comentari no pot ser modificat.",
    "error.commentDefaultCanNotDeleted": "El comentari no pot ser eliminat.",
    "error.groupAndCourseStageDontMatch": "El nivell, el curs i la matèria no coincideixen amb els valors del grup.",
    "error.canNotGenerateReportEmptyData": "No es pot generar l'informe perquè no hi ha dades.",
    "Access denied.": "Accés denegat.",
    "error.competenceCommentGradeExists": "Hi ha algun comentari d'una nota amb aquesta competència asociada",
    "error.competenceCompetenceEvaluableExists": "Hi ha algun avaluable amb aquesta competència asociada",
    "error.competenceProgramCompetenceExists": "Hi ha alguna programació amb aquesta competència asociada",
    "error.NoTemplateFoundForFunctionalityAndCourseStage": "No d'ha trobat cap plantilla per aquest curs.",
    "error.required.groupCourseStage": "Cal indicar el grup",
    "error.attendanceTypeNotInStage": "El tipus d'assistència no està activat per aquest curs.",
    "error.CourseStageNotEmptyPrograms": "No es pot eliminar el curs {curs} perquè conté programacions. Cal esborrar-les prèviament",
    "error.CourseStageNotEmptyConvocatories": "No es pot eliminar el curs {curs} perquè conté convocatòries. Cal esborrar-les prèviament",
    "error.GroupCourseStageNotFound": "No s'ha trobat el grup.",
    "error.PersonNotFound": "No s'ha trobat a la persona.",
    "error.ConvocatoryNotFound": "No s'ha trobat la convocatòria.",
    "error.ConvocatoryCodeCanNotBeEmpty": "El codi de la convocatòria no pot estar buit.",
    "error.ConvocatoryNameCanNotBeEmpty": "El nom de la convocatòria no pot estar buit.",
    "error.CenterNotFound": "No s'ha trobat el centre.",
    "error.ParentConvocatoryNotFound": "No s'ha trobat la convocatòria pare.",
    "error.ConvocatoryCanNotBeItsOwnParent": "La convocatòria no pot ser el seu propi pare.",
    "error.ConvocatoryHasDependencies": "La convocatòria té dependències.",
    "error.ConvocatoryHasParent": "La convocatòria té pares.",
    "error.ConvocatoriesIdsMustBeArray": "S'ha d'enviar un array d'ids de convocatòries.",
    "error.OneDateCanNotBeEmpty": "No pot estar buida la data.",
    "error.StartDateBeforeParentStartDate": "La data d'inici és anterior a la data d'inici del pare.",
    "error.EndDateAfterParentEndDate": "La data de fi és posterior a la data de finalització del pare.",
    "error.CantChangeParentOfConvocatoryWithGrades": "No es pot modificar el pare d'una convocatòria amb cursos.",
    "error.ConvocatoryIdCanNotBeEmpty": "L'id de la convocatòria no pot estar buit.",
    "error.PositionIsNotAnInteger": "La posició no és un número.",
    "error.dniExists": "Aquest DNI ja existeix.",
    "error.loadingPaymentMethods": "No ha sigut possible recuperar els mètodes de pagament.",
    "error.yearNotSelected": "Falta seleccionar l'any.",
    "error.PersonFieldNotEmpty": "No es pot modificar el tipus perquè hi ha persones amb dades d'aquest camp.",
    "error.personIsNotEnrolledToEvaluableProgram": "La persona no està matriculada a la matèria.",
    "error.hoursActivity": "Has d'introduir un nombre enter a la quantitat d'hores.",
    "error.bankAccountExistsOnSamePerson": "El número de compte ja està registrat",
    "error.PersonIsTeacherInTimetableDraftRepetition": "La persona està assignada com a professor en els següents esdeveniments de l'horari (la versió de l'horari encara no està activa):",
    "error.differentProgramClassAndGroup": "La programació de la classe no coincideix amb la programació associada al grup.",
    "error.createdCompanyButNotSynced": "L'empresa s'ha guardat correctament però no ha sigut possible sincronitzar amb KeyAndCloud.",
    "error.updatedCompanyButNotSynced": "L'empresa s'ha actualitzat correctament però no ha sigut possible sincronitzar amb KeyAndCloud.",
    "error.contactTypeValueMismatch": "El valor del contacte no és del tipus indicat.",
    "error.emailNotExists": "El correu no correspon a cap dels nostres usuaris al sistema.",
    "error.CopyDataToSchemaError": "Error en copiar les dades de l'any.",
    "error.yearNameExists": "Ja existeix aquest nom, provi amb un altre diferent.",
    "error.centerYearExists": "Ja existeix aquest curs.",
    "error.GroupCourseStageCourseStageIdNotMatchProgramCourseStageId": "S'està intentant afegir una planificació a un grup que té un curs diferent.",
    "error.billingDoesNotAllowToViewGradesForParent": "La visualització de notes està bloquejada perquè actualment tens un dubte vençut de ${payment}, comunica't amb la institució.",
    "error.stageTypeNotExists": "El nivell no existeix.",
    "error.ConvocatoryHasSiblingRecovery": "Ja hi ha una convocatòria de recuperació al mateix nivell. Només pot existir una convocatòria de recuperació per nivell.",
    "error.ConvocatoryHasSiblingRecoveryInactive": "Ja hi ha una convocatòria de recuperació al mateix nivell. Només pot existir una convocatòria de recuperació per nivell. Vols activar la convocatòria de recuperació existent per al curs actual?",
    "error.ConvocatoryHasNoParent": "La convocatòria no té cap nivell superior. És necessari que la convocatòria tingui un nivell superior per establir-la com a convocatòria de recuperació.",
    "error.ConvocatoryHasMoreThanOneParent": "La convocatòria té més d'un nivell superior. És necessari que la convocatòria tingui un únic nivell superior per establir-la com a convocatòria de recuperació.",
    "error.canNotDeleteTemplateBecauseOnlyOneActive": "No es pot esborrar la plantilla perquè és l'única activa per aquesta funcionalitat.",
    "error.ErrorActivatingTimetableCA": "S'ha produït un error en activar l'horari:",
    "error.GroupAndStudentCannotBeNull": "Falta el grup o l'alumne.",
    "error.SubjectCannotBeNull": "Falta la matèria.",
    "error.TeachersCannotBeNull": "Falta el professor.",
    "error.CourseStageCannotBeNull": "Falta el curs.",
    "error.StageCannotBeNull": "Falta el nivell.",
    "error.valueTooLong": "Error: el valor és massa llarg o has seleccionat masses elements",
    "error.billingDoesNotAllowToViewGradesForStudent": "La visualització de notes està bloquejada per cartera vençuda.",
    "error.personHaveEnrollment": "L'usuari té matricules actives.",
    "error.soonHaveEnrollment": "L'usuari és familiar d'un alumne amb matricules actives.",
    "error.minOneCenterRole": "L'usuari ha de tenir com a mínim un permís assignat a un centre.",
    "error.surname1Invalid": "El format del congom1 no es vàlid",
    "error.roomHasClasses": "L'aula està associada a una o diverses classes",
    "error.roomHasTimetableActiveEvents": "L'aula està associada a un o més horaris",
    "error.ParameterNotAllowedToChange": "No es pot canviar aquest paràmetre",
    "error.cannotSaveClasseContent": "No s'ha pogut guardar",
    "error.EntityIdNotExists": "L'entitat no existeix",
    "error.documentLinkedToClasses": "No ha sigut possible eliminar l'element. Hi ha un o més documents vinculats a Classes.",
    "error.StageNotExists": "El nivell no existeix",
    "error.CourseStageNotExists": "El curs no existeix",
    "error.SubjectNotExists": "La matèria no existeix",
    "error.CompetenceNotExists": "La competència no existeix",
    "error.EvaluabletypeValueNotExists": "El tipus de nota no existeix",
    "error.ValueRequired": "El valor és obligatori",
    "error.actionNotAllowed": "Acció no permesa.",
    "error.someRecipientsAreNotAllowed": "No té permís per comunicarse amb un o més contactes del missatge.",
    "error.quantityMustBeGreaterThan0": "Ha de ser major a 0",
    "error.quantityMustBeNumber": "Ha de ser un número",
    "error.notGlobalStage": "És necessari passar el Nivell a Global, abans que el curs.",
    "error.importGenericError": "Error a l'importar",
    "error.name_coursestage_idExists": "Ja existeix un curs amb el mateix nom.",
    "error.nameStageNotValid": "Nom de nivell incorrecte.",
    "error.nameCourseStageNotValid": "Nom de curs incorrecte.",
    "error.enrolmentNumNotValid": "Número de matricula incorrecte.",
    "error.courseStageNotDeletable": "No es pot eliminar el curs perquè té les següents dependències:",
    "error.automationNotActive": "L'automatisme de publicació d'horaris no està actiu.",
    "error.timetableActivationExists": "Ja existeix un procés de publicació pendent o actiu.",
    "error.canNotDeleteActivation": "No ha sigut possible cancel·lar la publicació de l'horari. És possible que el procés de publicació ja s'estigui executant.",
    "error.personNotSynced": "Persona no l'ha pogut vincular amb Key And Cloud",
    "error.some403": "No tens permisos per a veure alguns dels recursos d'aquesta pàgina.",
    "error.sendGradesReportWithoutContacts": "Revisar contactes",
    "error.nameSurname1Surname2InUse": "Ja existeix una persona amb el mateix nom i cognoms.",
    "error.activationDateNotValid": "Data d'activació no vàlida.",
    "error.deactivationDateNotValid": "Data desactivació no vàlida.",
    "error.endDateBeforeStartDate": "Data desactivació ha de ser superior a Data d'acitvació.",
    "error.groupIdOravailabilityIdNotValid": "Grup o disponibilitat no vàlids.",
    "error.groupCourseStageNotValid": "El Grup seleccionat no és vàlid.",
    "error.niaExists": "Ja existeix una persona amb el mateix identificador extern.",
    "error.personExistsChild": "L'alumne ja existeix",
    "error.personExistsFather": "El familiar 1 ja existeix",
    "error.personExistsMother": "El familiar 2 ja existeix",
    "error.cannotSaveGradesEvaluables": "No s'han pogut transferir les notes a l'avaluable",
    "error.personNoPayer": "La persona no s'ha vinculat amb Key And Cloud degut a que no té pagador definit",
    "error.programNotFound": "Planificació no trobada.",
    "error.originProgramIdNotValid": "El id de la planificació original no és vàlid.",
    "rror.originYearCodeNotValid": "L'any de la planificació original no és vàlid.",
    "error.targetProgramIdNotValid": "El id de la planificació destí no és vàlid.",
    "error.targetYearCodeNotValid": "L'any de la planificació destí no és vàlid.",
    "error.centerNotFound": "Centre no trobat.",
    "error.nothingToImport": "No hi ha informació per importar.",
    "error.targetSchemaNotValid": "Esquema destí no vàlid.",
    "error.originProgramNotFound": "No s'ha trobat la planificació original.",
    "error.targetProgramNotFound": "No s'ha trobat la planificació destí.",
    "error.convocatoriesMismatch": "Les estructures de convocatories no coincideixen.",
    "error.personNoPayer": "La persona no s'ha vinculat amb Key And Cloud degut a que no té pagador definit",
    "error.name_center_id_parent_idExists": "Ja existeix un element amb aquest nom per aquest pare. Vols activar-lo per al curs actual?",
    "error.convocatoryAlreadyActive": "Ja existeix un element amb aquest nom per aquest pare.",
    "error.code_center_idExists": "Ja existeix un element amb aquest mateix codi",
    "error.centerNotFound": "Centre no trobat.",
    "error.yearNotFound": "Any no trobat.",
    "error.programNotFound": "Planificació no trobada.",
    "error.contentTypeEmpty": "Tipus és obligatori.",
    "error.contentTypeNotValid": "Tipus no vàlid.",
    "error.contentTitleEmpty": "Títol és obligatori.",
    "error.convocatoriesNotFound": "Convocatòries no trobades.",
    "error.competenceNotFound": "Competència no trobada.",
    "error.contentOrCompetenceNotFound": "No es pot importar el contingut, dades incorrectes o insuficients (falta informació de contingut o la competència no existeix).",
    "error.serverNotResponding": "El servidor no respon.",
    "error.cantRemoveGroup": "No s'ha pogut eliminar el grup {group}. Error intern.",
    "error.person_id_preregistration_idExists": "Aquest usuari ja està registrat en aquesta preinscripció.",
    "error.preregistrationNotAvailable": "Preinscripció no disponible.",
    "error.preregistrationNotAvailableDescription": "Aquesta preinscripció no existeix o no està disponible en aquest moment.",
    "error.messageSubjectTooLong": "El assumpte del missatge és massa llarg. Limit 200 caràcters.",
    "error.messageSizeExceeded": "El missatge supera la mida màxima permesa. Limit: {limit}, Mida actual: {size}.",
    "error.idExists": "Aquest id ja existeix",
    "El valor seleccionat no és una opció vàlida.": "El valor seleccionat no és una opció vàlida.",
    "error.CompetenceHasChildWithAmbit": "No es pot treure l'àmbit <strong>'{ambitName}'</strong> de la competència <strong>'{dimensionName}'</strong> perquè el criteri <strong>'{competenceName}'</strong> pertany a aquest àmbit.",
    "error.CompetenceHasChildWithArea": "No es pot treure el departament <strong>'{ambitName}'</strong> de la competència <strong>'{dimensionName}'</strong> perquè el criteri <strong>e'{competenceName}'</strong> pertany a aquest departament.",
    "error.CompetenceMustHaveAmbits": "La competència o el criteri <strong>'{competenceName}'</strong> ha de pertànyer com a mínim a un àmbit.",
    "error.CompetenceHasChildWithoutAmbit": "No es pot treure l'àmbit <strong>'{ambitName}'</strong> del criteri <strong>'{competenceName}'</strong>, ja que aquest ha d'estar assignat com a mínim a un àmbit.",
    "error.CompetenceWithoutAmbit": "No es pot treure l'àmbit <strong>'{ambitName}'</strong> de la competència <strong>'{dimensionName}'</strong>, ja que aquest ha d'estar assignat com a mínim a un àmbit.",
    "error.imageSizeExceeded": "La imatge supera la mida màxima permesa. Limit: {limit}, Mida actual: {size}.",
    "error.errorReportDefinedForThisCourse": "No està ben configurat el butlletí definit per aquest curs.",
    "error.SlotRequired": "La franja horària és obligatòria",
    "error.SubjectRequired": "L'assignatura és obligatòria",
    "error.TeacherRequired": "El/la professor/a és obligatòri/a",

}
