export default{
    "gradesSubjectsCenterName": "Nombre del centro",
    "gradesSubjectsCenterLogo": "Logo del centro",
    "gradesSubjectsStudentName": "Nombre del alumno",
    "gradesSubjectsStageName": "Nombre del nivel",
    "gradesSubjectsEvaluationPeriodCode": "Código del período de evaluación",
    "gradesSubjectsCourseName": "Nombre del curso",
    "gradesSubjectsGroupName": "Nombre del grupo",
    "gradesSubjectsSubjectsList": "Listado de materias",
    "gradesSubjectsSubjectsGrade": "Nota de cada materia",
    "gradesSubjectsEvaluationPeriodsCode": "Código de cada período de evaluación",
    "gradesSubjectsEvaluationPeriodsGrade": "Nota de cada período de evaluación",
    "gradesSubjectsGradesComments": "Comentarios de notas",
    "gradesSubjectsTutorName": "Nombre del tutor",
    "gradesSubjectsEvaluationPeriodName": "Nombre del período de evaluación",
    "gradesSubjectsEvaluables": "Evaluables",
    "gradesSubjectsYearName": "Año",
    "gradesOnlyOneSubjectCenterLogo": "Logo del centro",
    "gradesOnlyOneSubjectCourseCode": "Código del curso",
    "gradesOnlyOneSubjectEvaluationPeriodName": "Nombre del período de evaluación",
    "gradesOnlyOneSubjectEvaluationPeriodsCode": "Códigos del período de evaluación",
    "gradesOnlyOneSubjectEvaluationPeriodsFinalGrade": "Nota final de cada período de evaluación",
    "gradesOnlyOneSubjectEvaluationPeriodsGrade": "Nota de cada evaluable del período de evaluación",
    "gradesOnlyOneSubjectTutorComments": "Comentarios del tutor",
    "gradesOnlyOneSubjectGradesComments": "Comentarios de notas",
    "gradesOnlyOneSubjectFinalGrade": "Nota final",
    "gradesOnlyOneSubjectStudentName": "Nombre del alumno",
    "gradesOnlyOneSubjectGroupName": "Nombre del grupo",
    "gradesOnlyOneSubjectTutorName": "Nombre del tutor",
    "gradesOnlyOneSubjectEvaluables": "Evaluables",
    "gradesOnlyOneSubjectYearName": "Año",
    "gradesCompetencesCenterLogo": "Logo del centro",
    "gradesCompetencesCenterName": "Nombre del centro",
    "gradesCompetencesCenterAddress": "Dirección del centro",
    "gradesCompetencesCenterPostalCode": "Código postal del centro",
    "gradesCompetencesCenterTown": "Población del centro",
    "gradesCompetencesCenterPhone": "Teléfono del centro",
    "gradesCompetencesStudentCompleteName": "Nombre completo del alumno",
    "gradesCompetencesGroupName": "Nombre del grupo",
    "gradesCompetencesTutorCompleteName": "Nombre del tutor",
    "gradesCompetencesCourseName": "Nombre del curso",
    "gradesCompetencesEvaluationPeriodName": "Nombre del período de evaluación",
    "gradesCompetencesDate": "Fecha",
    "gradesCompetencesCompetenceList": "Listado de competencias",
    "gradesAreaTableName": "Area",
    "gradesAreaTableGrade": "Nota area",
    "gradesAreaTableAssessment": "Valoración area",
    "gradesAreaTableSubjects": "Materias",
    "gradesAreaTableSubjectsName": "Nombre materia",
    "gradesAreaTableSubjectsHourlyIntensity": "Intensidad horaria",
    "gradesAreaTableSubjectsGrade": "Nota materia",
    "gradesAreaTableSubjectsCompetencesName": "Comentarios materia",
    "gradesAreaTableSubjectsCompetencesAssessment": "Categoria comentario",
    "gradesAreaTableEvaluationPeriods": "Periodos de evaluación",
    "gradesAreaTableEvaluationPeriodsGrade": "Notas periodos de evaluación",
    "gradesAreaTableEvaluationPeriodsCode": "Códigos periodos de evaluación",
    "gradesAreaTable": "Tabla de notas del alumno",
    "gradesAreaCenterLogo": "Logo del centro",
    "gradesAreaCenterName": "Nombre del centro",
    "gradesAreaGroupName":"Nombre del grupo",
    "gradesAreaStudentName":"Nombre del alumno",
    "gradesAreaTableSubjectsCompetences": "Competencias",
    "gradesAreaTutorName": "Nombre del tutor",
    "gradesAreaEvaluationPeriodCode": "Código período de evaluación",
    "gradesAreaYearName": "Año",
    "gradesAreaPreschoolCenterLogo": "Logo del centro",
    "gradesAreaPreschoolCenterName": "Nombre del centro",
    "gradesAreaPreschoolGroupName":"Nombre del grupo",
    "gradesAreaPreschoolStudentName":"Nombre del alumno",
    "gradesAreaPreschoolTutorName": "Nombre del tutor",
    "gradesAreaPreschoolEvaluationPeriodCode": "Código período de evaluación",
    "gradesAreaPreschoolYearName": "Año",
    "gradesAreaPreschoolAbsenceCount": "Faltas",
    "gradesAreaAbsenceCount": "Faltas",
    "gradesAreaRanking": "Puesto",
    "gradesAreaPreschoolAreaTable": "Area",
    "gradesAreaPreschoolAreaTableName": "Nombre area",
    "gradesAreaPreschoolAreaTableGradesComments": "Comentarios area",
    "gradesAreaPartialStudyCertificateStudentName": "Nombre del alumno",
    "gradesAreaPartialStudyCertificateTI": "TI del alumno",
    "gradesAreaPartialStudyCertificateCourse": "Curso",
    "gradesAreaPartialStudyCertificateStageType": "Tipo de estudio",
    "gradesAreaPartialStudyCertificatePeriod": "Período",
    "gradesAreaPartialStudyCertificateEnrolment": "Matricula",
    "gradesAreaPartialStudyCertificateCalif": "Calificación",
    "gradesAreaPartialStudyCertificateOrder": "Orden",
    "gradesAreaPartialStudyCertificateAreaSection": "Sección area",
    "gradesAreaPartialStudyCertificateAreaSectionGrade": "Nota area",
    "gradesAreaPartialStudyCertificateAreaSectionAssessment": "Valoración area",
    "gradesAreaPartialStudyCertificateAreaSectionName": "Nombre area",
    "gradesAreaPartialStudyCertificateAreaSectionSubjects": "Materias",
    "gradesAreaPartialStudyCertificateAreaSectionSubjectsName": "Nombre materia",
    "gradesAreaPartialStudyCertificateAreaSectionSubjectsHourlyIntensity": "Intensidad horaria",
    "gradesAreaPartialStudyCertificateAreaSectionSubjectsGrade": "Nota materia",
    "gradesAreaPartialStudyCertificateCenterLogo": "Logo del centro",
    "gradesAreaPartialStudyCertificateCenterName": "Nombre del centro",
    "gradesAreaFinalStudyCertificateCenterLogo": "Logo del centro",
    "gradesAreaFinalStudyCertificateCenterName": "Nombre del centro",
    "gradesAreaFinalStudyCertificateGroupName": "Nombre del grupo",
    "gradesAreaFinalStudyCertificateStudentName": "Nombre del alumno",
    "gradesAreaFinalStudyCertificateTutorName": "Nombre del tutor",
    "gradesAreaFinalStudyCertificateEvaluationPeriodCode": "Código período de evaluación",
    "gradesAreaFinalStudyCertificateYearName": "Año",
    "gradesAreaFinalStudyCertificateTI": "TI del alumno",
    "gradesAreaFinalStudyCertificateCourse": "Curso",
    "gradesAreaFinalStudyCertificateStageType": "Tipo de estudio",
    "gradesAreaFinalStudyCertificatePeriod": "Período",
    "gradesAreaFinalStudyCertificateEnrolment": "Matricula",
    "gradesAreaFinalStudyCertificateCalif": "Calificación",
    "gradesAreaFinalStudyCertificateOrder": "Orden",
    "gradesAreaFinalStudyCertificateTable": "Area",
    "gradesAreaFinalStudyCertificateTableName": "Nombre area",
    "gradesAreaFinalStudyCertificateTableGrade": "Nota area",
    "gradesAreaFinalStudyCertificateTableAssessment": "Valoración area",
    "gradesAreaFinalStudyCertificateTableEvaluationPeriods": "Periodos de evaluación",
    "gradesAreaFinalStudyCertificateTableEvaluationPeriodsCode": "Códigos periodos de evaluación",
    "gradesAreaFinalStudyCertificateTableEvaluationPeriodsGrade": "Notas periodos de evaluación",
    "gradesAreaFinalStudyCertificateTableSubjects": "Materias",
    "gradesAreaFinalStudyCertificateTableSubjectsName": "Nombre materia",
    "gradesAreaFinalStudyCertificateTableSubjectsHourlyIntensity": "Intensidad horaria",
    "gradesAreaFinalStudyCertificateTableSubjectsGrade": "Nota materia",
    "gradesAreaFinalStudyCertificateTableFinalEvaluationPeriod": "Período de evaluación final",
    "gradesAreaFinalStudyCertificateTableFinalEvaluationPeriodCode": "Código periodo de evaluación final",
    "gradesAreaFinalStudyCertificateTableFinalEvaluationPeriodGrade": "Nota periodo de evaluación final",
    "gradesAreaFinalStudyCertificateDate": "Fecha actual",
    "gradesAreaFinalStudyCertificateEvaluationPeriods": "Periodos de evaluación",
    "gradesAreaFinalStudyCertificateEvaluationPeriodsCode": "Códigos periodos de evaluación",
    "gradesAreaFinalStudyCertificateFinalEvaluationPeriodCode": "Código periodo de evaluación final",
    "gradesAreaCode": "Código",
    "enrolmentStudentContactValue": "Valor contacto",
    "enrolmentSubjectName": "Nombre materia",
    "enrolmentStudentTutorName": "Nombre tutor legal",
    "enrolmentStudentTutoraName": "Nombre tutora legal",
    "enrolmentStudentTutorID": "Documento de identidad tutor legal",
    "enrolmentStudentTutoraID": "Documento de identidad tutora legal",
    "enrolmentTimetable": "Horario",
    "gradesAreaPreschoolCode": "Código",
    "enrolmentClientName": "Nombre del cliente",
    "enrolmentClientID": "Documento de identidad del cliente",
    "enrolmentClientAddreess": "Dirección del cliente",
    "enrolmentClientEmail": "Correo electrónico del cliente",
    "enrolmentClientPhone": "Teléfono del cliente",
    "enrolmentStudentEmail": "Correo electrónico del alumno",
    "enrolmentStudentPhone": "Teléfono del alumno",
    "enrolmentCourseStartDate": "Fecha inicio curso",
    "enrolmentCourseEndDate": "Fecha final curso",
    "enrolmentCourseLanguage": "Idioma curso",
    "enrolmentLargeDate": "Fecha larga",
    "printGradesGridCenterName": "Nombre del centro",
    "printGradesGridCenterLogo": "Logo del centro",
    "printGradesGridStudentName": "Nombre del alumno/a",
    "printGradesGridTutorName": "Nombre del profesor/a",
    "printGradesGridGroupName": "Nombre del grupo",
    "printGradesGridGrades": "Notes",
    "printGradesGridSubjectName": "Nombre de la materia",
    "printGradesGridDimensionName": "Nombre de la competencia",
    "printGradesGridCompetenceName": "Nombre del criterio",
    "printGradesGridCompetenceGrade": "Nota del criterio",
    "printGradesGridPeriodName": "Convocatoria",
    "printGradesGridYearName": "Curso escolar",
    "printGradesGridDimensionGrades": "Notas competencia",
    "enrolmentYearName": "Año académico",
    "printGradesComments": "Informe de notas por comentarios",
    "printGradesCommentsStudentName": "Nombre del alumno/a",
    "printGradesCommentsTutorName": "Nombre del profesor/a",
    "printGradesCommentsYearName": "Curso escolar",
    "printGradesCommentsSubjectName": "Nombre de la materia",
    "printGradesCommentsSubjectComments": "Comentarios",
    "printGradesCommentsGrades": "Notas",
    "communicationsName": "Nombre",
    "communicationsSurname1": "Primer apellido",
    "communicationsSurname2": "Segundo apellido",
    "communicationsDni": "DNI",
    "communicationsAddress": "Dirección",
    "communicationsTown": "Población",
    "communicationsProvince": "Provincia",
    "communicationsCountry": "País",
    "communicationsZip": "Código postal",
    "communicationsUsername": "Nombre de usuario",
    "communicationsYearActive": "Año académico",
    "communicationsStage": "Nivel",
    "communicationsCourseStage": "Curso",
    "communicationsGroupCourseStage": "Grupo",
    "communicationsSubject": "Materia",
    "communicationsTimeSlot": "Franja horária",
    "communicationsRoom": "Aula",
    "communicationsTeacher": "Profesor/a",
    "communicationsLogo": "Logo",
    "communicationsStudentCompleteName": "Nombre alumnado completo",
    "communicationsCompleteName": "Nombre completo",
    "shortDate": "Fecha corta",
    "gradesAreaEquivalencySelectorAE": "Valoración area (A-E)",
    "gradesAreaEquivalencySelectorIE": "Valoración area (Insuficiente-Excelente)",
    "gradesAreaEquivalencySelector15": "Valoración area (1-5)",
    "gradesAreaEquivalencySelectorBasicSuperior": "Valoración area (Básico-Superior)",
    "gradesAreaEquivalencySelectedAchievedInProcess": "Valoración area (Alcanzado-En Progreso)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorAE": "Valoración area (A-E)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorIE": "Valoración area (Insuficiente-Excelente)" ,
    "gradesAreaPartialStudyCertificateEquivalencySelector15": "Valoración area (1-5)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorBasicSuperior": "Valoración area (Básico-Superior)",
    "gradesAreaPartialStudyCertificateEquivalencySelectedAchievedInProcess": "Valoración area (Alcanzado-En Progreso)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorAE": "Valoración area (A-E)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorIE": "Valoración area (Insuficiente-Excelente)",
    "gradesAreaFinalStudyCertificateEquivalencySelector15": "Valoración area (1-5)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorBasicSuperior": "Valoración area (Básico-Superior)",
    "gradesAreaFinalStudyCertificateEquivalencySelectedAchievedInProcess": "Valoración area (Alcanzado-En Progreso)",
    "printGradesEICACenterLogo": "Logo del centro",
    "printGradesEICACourseName": "Nombre del curso",
    "printGradesEICAStudentName": "Nombre del alumno",
    "printGradesEICATeacherName": "Nombre del profesor",
    "printGradesEICACompetencesTable": "Tabla de competencias",
    "printGradesEICACompetencesTableCompetenceName": "Nombre de la competencia",
    "printGradesEICACompetencesTableCompetencePercentage": "Porcentaje competencia",
    "printGradesEICACompetencesTableCompetenceGrade": "Nota competencia",
    "printGradesEICAFinalGrade": "Nota final",
    "printGradesEICAObservationsTable": "Tabla de observaciones",
    "printGradesEICAObservationsTableValue": "Observación",
    "gradesAreaBookTable": "Tabla de notas",
    "gradesAreaBookTableName": "Nombre del área",
    "gradesAreaBookTableGrade": "Nota del área",
    "gradesAreaBookTableAssessment": "Valoración del área",
    "gradesAreaBookTableSubjects": "Materias del área",
    "gradesAreaBookTableSubjectsName": "Nombre de la materia",
    "gradesAreaBookTableSubjectsHourlyIntensity": "IH",
    "gradesAreaBookTableSubjectsGrade": "Nota de la materia",
    "gradesAreaBookCenterLogo": "Logo del centro",
    "gradesAreaBookCenterName": "Nombre del centro",
    "gradesAreaBookGroupName": "Nombre del grupo",
    "gradesAreaBookStudentName": "Nombre del alumno",
    "gradesAreaBookTutorName": "Nombre del tutor",
    "gradesAreaBookYearName": "Año",
    "gradesAreaBookPromoted": "Promociona",
    "gradesAreaBookReinforcementTable": "Tabla de recuperaciones",
    "gradesAreaBookReinforcementTableSubjectTable": "Tabla de materias de recuperación",
    "gradesAreaBookReinforcementTableSubjectTableName": "Nombre de la materia recuperada",
    "gradesAreaBookReinforcementTableSubjectTableGrade": "Nota de la materia recuperada",
    "gradesAreaBookReinforcementTableSubjectTableAssessment": "Valoración de la materia recuperada",
    "gradesAreaBookReinforcementTableSubjectTableDate": "Fecha de recuperación",
    "gradesAreaBookEvaluationPeriod": "Período de evaluación",
    "gradesAreaBookCode": "Código",
    "gradesAreaBookPaperNumber": "Folio",
    "gradesAreaBookEquivalencySelectorAE": "Valoración area (A-E)",
    "gradesAreaBookEquivalencySelectorIE": "Valoración area (Insuficiente-Excelente)",
    "gradesAreaBookEquivalencySelector15": "Valoración area (1-5)",
    "gradesAreaBookEquivalencySelectorBasicSuperior": "Valoración area (Básico-Superior)",
    "gradesAreaBookEquivalencySelectedAchievedInProcess": "Valoración area (Alcanzado-En Progreso)",
    "gradesAreaBookReinforcementEquivalencySelectorAE": "Valoración recuperación (A-E)",
    "gradesAreaBookReinforcementEquivalencySelectorIE": "Valoración recuperación (Insuficiente-Excelente)",
    "gradesAreaBookReinforcementEquivalencySelector15": "Valoración recuperación (1-5)",
    "gradesAreaBookReinforcementEquivalencySelectorBasicSuperior": "Valoración recuperación (Básico-Superior)",
    "gradesAreaBookReinforcementEquivalencySelectedAchievedInProcess": "Valoración recuperación (Alcanzado-En Progreso)",
    "gradesAreaPositionAverageLevelCenterLogo": "Logo del centro",
    "gradesAreaPositionAverageLevelPage": "Página",
    "gradesAreaPositionAverageLevelYear": "Año",
    "gradesAreaPositionAverageLevelEvaluationPeriod": "Período de evaluación",
    "gradesAreaPositionAverageLevelDate": "Fecha",
    "gradesAreaPositionAverageLevelTable": "Tabla de promedio de nivel",
    "gradesAreaPositionAverageLevelTableStage": "Nivel",
    "gradesAreaPositionAverageLevelTableAverage": "Promedio nivel",
    "gradesAreaPositionAverageLevelTableStudentsTable": "Tabla de estudiantes",
    "gradesAreaPositionAverageLevelTableStudentsTableCode": "Código",
    "gradesAreaPositionAverageLevelTableStudentsTableStudent": "Estudiante",
    "gradesAreaPositionAverageLevelTableStudentsTableOrder": "Orden",
    "gradesAreaPositionAverageLevelTableStudentsTableScore": "Puntuación",
    "gradesAreaPositionAverageLevelTableStudentsTableAverage": "Promedio estudiante",
    "gradesAreaTableSubjectsComments": "Comentarios de asignatura",
    "gradesAreaTableSubjectsCommentsValue": "Valor de comentarios de asignatura",
    "gradesAreaTableSubjectsCommentsCategory": "Categoría de comentarios de asignatura",
    "gradesAreaTableAreaComments": "Comentarios de área",
    "gradesAreaTableAreaCommentsValue": "Valor de comentarios de área",
    "gradesAreaStudentTI": "TI del estudiante",
    "gradesAreaCourseName": "Nombre del curso",
    "gradesAreaStageType": "Tipo de etapa",
    "gradesAreaEvaluationPeriodName": "Nombre de período de evaluación",
    "gradesAreaFolEnrolment": "Número de matrícula",
    "gradesAreaDate": "Fecha",
    "gradesAreaStudentCode": "Código del estudiante",
    "gradesAreaTableGradeEquivalencySelectorAE": "Selector de equivalencia de nota de área (A-E)",
    "gradesAreaTableGradeEquivalencySelectorIE": "Selector de equivalencia de nota de área (Insuficiente-Excelente)",
    "gradesAreaTableGradeEquivalencySelector15": "Selector de equivalencia de nota de área (1-5)",
    "gradesAreaTableGradeEquivalencySelectorBasicSuperior": "Selector de equivalencia de nota de área (Básico-Superior)",
    "gradesAreaTableGradeEquivalencySelectedAchievedInProcess": "Selector de equivalencia de nota de área (Alcanzado-En Progreso)",
    "gradesAreaPromoted": "Promociona",
    "gradesAreaPaperNumber": "Número de folio",
    "gradesAreaReinforcementTable": "Tabla de recuperación",
    "gradesAreaReinforcementTableSubjectTable": "Tabla de asignaturas de recuperación",
    "gradesAreaReinforcementTableSubjectTableName": "Nombre de asignatura de recuperación",
    "gradesAreaReinforcementTableSubjectTableGrade": "Nota de asignatura de recuperación",
    "gradesAreaReinforcementTableSubjectTableAssessment": "Valoración de asignatura de recuperación",
    "gradesAreaReinforcementTableSubjectTableDate": "Fecha de recuperación",
    "gradesAreaTableFinalEvaluationPeriodCode": "Código de período de evaluación final",
    "gradesAreaTableFinalEvaluationPeriodGrade": "Nota de período de evaluación final",
    "gradesAreaNotFinalEvaluationPeriods": "Períodos de evaluación no finales",
    "gradesAreaNotFinalEvaluationPeriodsCode": "Codigo de período de evaluación no final",
    "gradesAreaNotFinalEvaluationPeriodsGrade": "Nota de período de evaluación no final",
    "basicCommunicationsName": "Nombre destinatario",
    "basicCommunicationsCenterLogo": "Logo centro",
    "basicCommunicationsStudentName": "Nombre alumno",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorAE": "Selector de equivalencia de nota de asignatura de recuperación (A-E)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorIE": "Selector de equivalencia de nota de asignatura de recuperación (Insuficiente-Excelente)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelector15": "Selector de equivalencia de nota de asignatura de recuperación (1-5)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorBasicSuperior": "Selector de equivalencia de nota de asignatura de recuperación (Básico-Superior)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectedAchievedInProcess": "Selector de equivalencia de nota de asignatura de recuperación (Alcanzado-En Progreso)",
    "printGradesEICAYearName": "Año",
    "printGradesEICAConvocatoryName": "Convocatoria",
    "gradesAreaCenterIcon": "Icono del centro",
    "gradesAreaPositionAverageLevelTableStudentsTableAmbit": "Ámbito",
    "gradesAreaPositionAverageLevelTableStudentsTableAmbitSubject": "Materia",
    "gradesLevelAmbitCenterLogo": "Logo del centro",
    "gradesLevelAmbitPage": "Página",
    "gradesLevelAmbitYear": "Año",
    "gradesLevelAmbitEvaluationPeriod": "Periodo de evaluación",
    "gradesLevelAmbitDate": "Fecha",
    "gradesLevelAmbitTable": "Tabla",
    "gradesLevelAmbitStage": "Nivel",
    "gradesLevelAmbitStageTable": "Tabla nivel",
    "gradesLevelAmbitStageTableStudent": "Estudiante",
    "gradesLevelAmbitStageTableStudentTable": "Tabla de estudiantes",
    "gradesLevelAmbitStageTableStudentTableAmbit": "Ámbito",
    "gradesLevelAmbitStageTableStudentTableAmbitTable": "Tabla de ámbitos",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubject": "Asignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubjectTable": "Tabla de asignaturas",
    "gradesLevelAmbitConvocatories": "Convocatorias",
    "gradesLevelAmbitMediumGrades": "Medianas",
    "gradesLevelAmbitGrades": "Notas",
    "gradesAmbitLevelCenterLogo": "Logo del centro",
    "gradesAmbitLevelPage": "Página",
    "gradesAmbitLevelYear": "Año",
    "gradesAmbitLevelEvaluationPeriod": "Periodo de evaluación",
    "gradesAmbitLevelDate": "Fecha",
    "gradesAmbitLevelAmbitTableStage": "Nivel",
    "gradesAmbitLevelAmbitTableStageTable": "Tabla nivel",
    "gradesAmbitLevelAmbitTableStageTableStudent": "Estudiante",
    "gradesAmbitLevelAmbitTableStageTableStudentTable": "Tabla de estudiante",
    "gradesAmbitLevelAmbit": "Ámbito",
    "gradesAmbitLevelAmbitTable": "Tabla de Ámbito",
    "gradesAmbitLevelConvocatories": "Convocatorias",
    "gradesAmbitLevelGrades": "notas",
    "gradesAmbitLevelCourseCode": "Código Curso",
    "gradesSubjectLevelCenterLogo": "Logo del centro",
    "gradesSubjectLevelPage": "Página",
    "gradesSubjectLevelYear": "Año",
    "gradesSubjectLevelEvaluationPeriod": "Periodo de evaluación",
    "gradesSubjectLevelDate": "Fecha",
    "gradesSubjectLevelSubjectTableStage": "Nivel",
    "gradesSubjectLevelSubjectTableStageTable": "Tabla nivel",
    "gradesSubjectLevelSubjectTableStageTableStudent": "Estudiante",
    "gradesSubjectLevelSubjectTableStageTableStudentTable": "Tabla de estudiante",
    "gradesSubjectLevelSubject": "Materia",
    "gradesSubjectLevelSubjectTable": "Tabla de materias",
    "gradesSubjectLevelConvocatories": "Convocatorias",
    "gradesSubjectLevelGrades": "notas",
    "gradesSubjectLevelCourseCode": "Código Curso",
    "enrolmentSubjectCenterLogo": "Logo del centro",
    "enrolmentSubjectPage": "Página",
    "enrolmentSubjectYear": "Año",
    "enrolmentSubjectDate": "Fecha",
    "enrolmentSubjectEvaluationPeriod": "Periodo de evaluación",
    "enrolmentSubjectCode": "Código materia",
    "enrolmentSubjectCourseCode": "Código Curso",
    "enrolmentSubjectStage": "Nivel",
    "enrolmentSubjectEnrolments": "Núm. matrículas",
    "enrolmentSubjectStageEnrolments": "Matrículas Curso",
    "enrolmentRegistrationDate": "Fecha alta",
    "enrolmentTerminationDate": "Fecha baja",
    "preregistrationNotificationsRecipientName": "Nombre destinatario",
    "preregistrationNotificationsName": "Nombre preinscripción",
    "preregistrationNotificationsDateActivation": "Fecha inicio preinscripción",
    "preregistrationNotificationsDateDeactivation": "Fecha fin preinscripción",
    "preregistrationNotificationsAvailability": "Disponibilidad preinscripción",
    "preregistrationNotificationsLink": "Enlace preinscripción",
    "preregistrationNotificationsCenter": "Nombre centro",
    "preregistrationNotificationsLogo": "Logo centro",
    "currentDate": "Fecha actual",
    "enrolmentStudentId": "ID del alumno",
    "enrolmentStudentBrothers": "Hermanos del alumno",
    "enrolmentStudentBrothersSurnames": "Apellidos del hermano",
    "enrolmentStudentBrothersName": "Nombre del hermanos",
    "enrolmentStudentBrothersAddreess": "Dirección del hermano",
    "enrolmentStudentBrothersPostalCode": "Código postal del hermano",
    "enrolmentStudentBrothersPopulation": "Población del hermano",
    "enrolmentStudentBrothersProvince": "Provincia del hermano",
    "enrolmentStudentBrothersDNI": "DNI del hermano",
    "enrolmentStudentBrothersEmail": "Correo electrónico del hermano",
    "enrolmentStudentBrothersPhone": "Teléfono del hermano",
    "enrolmentStudentBrothersMobile": "Móvil del hermano",
    "enrolmentStudentTutors": "Tutores del alumno",
    "enrolmentStudentTutorsSurnames": "Apellidos del tutor",
    "enrolmentStudentTutorsName": "Nombre del tutor",
    "enrolmentStudentTutorsAddreess": "Dirección del tutor",
    "enrolmentStudentTutorsPostalCode": "Código postal del tutor",
    "enrolmentStudentTutorsPopulation": "Población del tutor",
    "enrolmentStudentTutorsProvince": "Provincia del tutor",
    "enrolmentStudentTutorsDNI": "DNI del tutor",
    "enrolmentStudentTutorsEmail": "Correo electrónico del tutor",
    "enrolmentStudentTutorsPhone": "Teléfono del tutor",
    "enrolmentStudentTutorsMobile": "Móvil del tutor",
    "enrolmentStudentBrothersBirthdate": "Fecha de nacimiento del hermano",
    "enrolmentStudentBrothersStage": "Nivel del hermano",
    "enrolmentStudentBrothersCourseStage": "Curso del hermano",
    "enrolmentStudentBrothersGroupStage": "Grupo del hermano",
    "enrolmentStudentName": "Nombre del alumno",
    "enrolmentStudentSurnames": "Apellidos del alumno",
    "gradesProgressCenterLogo": "Logo centre",
    "gradesProgressStudentName": "Nombre estudiante",
    "gradesProgressTeachersName": "Nombre profesores",
    "gradesProgressYear": "Año lectivo",
    "gradesProgressCourseStage": "Curso",
    "gradesProgressTable": "Tabla progreso",
    "gradesProgressTableSubject": "Materia",
    "gradesProgressTableSubjectTable": "Tabla materias",
    "gradesProgressTableSubjectTableCompetenceTable": "Tabla competencias",
    "gradesProgressTableSubjectTableCompetence": "Competencia",
    "gradesProgressTableSubjectTableCompetenceTableCriterion": "Criterio",
    "gradesProgressTableSubjectTableCompetenceTableCriterionTable": "Tabla de criterios",
    "gradesProgressTableSubjectTableCompetenceTableGrades": "Notas",
    "gradesProgressAttendanceTable": "Tabla asistencia",
    "gradesPrimaryCenterLogo": "Logo centro",
    "gradesPrimaryYear": "Año lectivo",
    "gradesPrimaryStudentName": "Nombre estudiante",
    "gradesPrimaryTeacherName": "Nombre profesor",
    "gradesPrimaryCourseStage": "Curso",
    "gradesPrimaryCompetenceTable": "Tabla competencias",
    "gradesPrimarySubjectsTable": "Tabla materias",
    "gradesPrimaryCompetenceTableCompetence": "Competencia",
    "gradesPrimarySubjectsTableSubject": "Materia",
    "gradesPrimaryClassAttendance": "Resumen de asistencia",
    "gradesPrimaryPercentageAttendance": "Porcentaje de asistencia",
    "gradesPrimaryPercentageAbsence": "Porcentaje de inasistencia",
    "gradesPrimaryPromoted": "Promoción estudiante",
    "gradesPrimaryComments": "Observaciones",
    "monthlyReportCenterLogo": "Logo centro",
    "monthlyReportStudentName": "Nombre estudiante",
    "monthlyReportStudentId": "Id estudiante",
    "monthlyReportTeacherName": "Nombre profesor",
    "monthlyReportCourseStage": "Curso",
    "monthlyReportSubjectTable": "Tabla materias",
    "monthlyReportSubjectTableSubject": "Materia",
    "monthlyReportSubjectTableTeacher": "Profesor",
    "monthlyReportSubjectGradesTable": "Notas",
    "monthlyReportGpaTable": "Tabla GPA",
    "monthlyReportGpaValue": "Valor GPA",
    "printGradesPrimaryGrillReport": "Report Notas nivel Primario",
    "printGradesGrillReportCenterLogo": "Logo centro",
    "printGradesGrillReportStudentName": "Nombre estudiante",
    "printGradesGrillReportCourseStage": "Curso",
    "printGradesGrillReportYear": "Año escolar",
    "printGradesGrillReportGroup": "Grupo",
    "printGradesGrillReportPeriods": "Periodos",
    "printGradesGrillReportConvocatories": "Convocatorias",
    "printGradesGrillReportSubjectsTableSubject": "Asignatura",
    "printGradesGrillReportSubjectsTable": "Tabla asignaturas",
    "printGradesGrillReportSubjectsTableGrades": "Notas",
    "printGradesGrillReportAverages": "Promedios",
    "printGradesGrillReportAttendanceTable": "Resumen asistencia",
    "printGradesGrillReportComments": "Comentarios",
    "learningReportCenterLogo": "Logo centro",
    "learningReportYear": "Año escolar",
    "learningReportStudentName": "Nombre estudiante",
    "learningReportTeacherName": "Nombre Profesor",
    "learningReportSchoolPrincipal": "Director",
    "learningReportGradesTable": "Notas",
    "learningReportComments": "Comentarios",
    "monthlyReportHonorStudent": "Honor Student",
    "gradesAreaTableSubjectsEvaluationPeriodsGrade": "Nota materia por período de evaluación",
    "gradesAreaTableSubjectsMedianGrade": "Nota media de la materia",
    "gradesLevelAmbitTableStageTableStudentTableAmbitTableSubjectTable": "Tabla de asignaturas",
    "gradesLevelAmbitTableStageTableStudentTableAmbitTableSubjectTableGrades": "Notas de asignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableTableSubject": "Nombre asignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubjectTableGrades": "Notas de asignatura",
    "gradesAreaTableSubjectsGradeEquivalencySelectorBasicSuperior": "Selector d'equivalència de nota d'assignatura (Bàsic-Superior)",
    "printGradesLeadervilleStudent": "Alumnado",
    "printGradesLeadervilleCourse": "Curso",
    "printGradesLeadervilleTeachers": "Profesores",
    "printGradesLeadervilleYear": "Año académico",
    "printGradesLeadervilleCenterLogo": "Logo centro",
    "printGradesLeadervilleAmbitTable": "Tabla ámbitos",
    "printGradesLeadervilleAmbitName": "Nombre ámbito",
    "printGradesLeadervilleAmbitCompetences": "Competencias materia",
    "printGradesLeadervilleAmbitCompetenceName": "Nombre competencia",
    "printGradesLeadervilleAmbitCompetenceGrades": "Notas competencia",
    "printGradesLeadervilleAmbitSubjects": "Materias ámbito",
    "printGradesLeadervilleAmbitSubjectName": "Nombre materia",
    "printGradesLeadervilleAmbitSubjectGrades": "Notas materia",
    "printGradesLeaderVilleAmbitPeriods": "Convocatorias",
    "gradesPreescolarSaintJohnStudentName": "Nombre del alumno",
    "gradesPreescolarSaintJohnTeachersNames": "Nombre de los profesores",
    "gradesPreescolarSaintJohnConvocatories": "Convocatorias",
    "gradesPreescolarSaintJohnEvaluableTypeValues": "Valores de los tipos evaluables",
    "gradesPreescolarSaintJohnAmbitsTables": "Tablas de ámbitos",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTable": "Tabla de ámbito",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableAmbitName": "Nombre del ámbito",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTable": "Tabla de competencias",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCompetenceName": "Nombre de la competencia",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTable": "Tabla de criterios",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTableCriterionName": "Nombre del criterio",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTableGrade": "Nota del criterio",
    "gradesPreescolarSaintJohnCenterLogo": "Logo del centro",
    "gradesPreescolarSaintJohnComments": "Comentarios",
    "gradesPreescolarSaintJohnYear": "Año",
    "gradesPreescolarSaintJohnStage": "Nivel",
    "gradesPreescolarSaintJohnCourse": "Curso",
    "gradesPreescolarSaintJohnCurrentConvocatory": "Convocatoria actual",
    "centerLogo": "Logo del centro",
    "studentGroup": "Grupo / Sección",
    "centerYear": "Periodo académico",
    "printGradesPrimaryTeachersSubjectsEnrolment": "Número de matrícula",
    "printGradesPrimaryTeachersSubjectsTable": "Tabla de notas por materia y profesor",
    "printGradesPrimaryTeachersSubjectsTableSubjectNum": "Núm matèria",
    "printGradesPrimaryTeachersSubjectsTableSubjectName": "Nombre materia",
    "printGradesPrimaryTeachersSubjectsTableSubjectCredits": "Créditos materia",
    "printGradesPrimaryTeachersSubjectsTableSubjectTeacher": "Profesor/a materia",
    "printGradesPrimaryTeachersSubjectsTableSubjectGrades": "Notas materia",
    "printGradesPrimaryTeachersSubjectsGrade": "Grado",
}
