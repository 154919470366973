export default {
    "importadorrestoresession": "¿Tienes una sesión de importación sin finalizar, quieres continuarla?",
    "import.nav.file" : "Importar",
    "import.nav.matching" : "Relacionar campos",
    "import.nav.preview" : "Previsualizar",
    "import.nav.result" : "Resultado",

    "import.entity.room" : "espacio|espacios",
    "import.entity.subject" : "materia|materias",
    "import.entity.academicstructure" : "estructura académica",
    "import.entity.academicstructureBasic" : "estudios básicos",
    "import.entity.academicstructureAcademic" : "estudios no reglados",
    "import.entity.student" : "alumno|alumnado",
    "import.entity.teacher" : "profesor|profesorado",
    "import.entity.contact" : "contacto|contactos",
    "import.entity.user" : "usuario|usuarios",
    "import.entity.group" : "grupo|grupos",
    "import.entity.enrolment" : "matrícula|matrículas",
    "import.entity.area" : "departamento|departamentos",
    "import.entity.commentGradesDefault" : "comentarios predefinidos",
    "import.entity.program": "programación",
    "import.entity.planning": "planificación",
    "import.entity.TimeTableDraftEventRepetition" : "horario",

    'import.breadcrumb.import' : 'Importador',
    'import.breadcrumb.selecttype' : 'Selección del tipo de importación',
    'import.breadcrumb.preview' : 'Previsualizar resultados',
    "import.index.restoresession" :  "Hay una sesión anterior de importación sin finalizar del",
    "import.index.continuesession" :  "¿Quieres continuarla?",
    "import.existing": "Importación existente",

    'import.choosefile.import': 'Importador',
    'import.choosefile.hint': 'Arrastra tus archivos aquí o navega',
    'import.choosefile.continue': 'Continuar',
    'import.choosefile.download': 'Descargar',
    'import.choosefile.download_samples': 'Plantilla',
    'import.choosefile.download_actual_data': 'Datos actuales',
    'import.choosefile.parseError': "Error al procesar el archivo",
    'import.choosefile.importFile': "Importar archivo",
    'import.choosefile.drag.hint': "Arrastra el archivo a cualquier punto de la pantalla",

    'import.matching.headingOnFirstRow': 'No importar la primera fila',
    'import.matching.field': "campo",
    'import.matching.notMapped': "sin relacionar",
    'import.matching.matching': "Relacionar campos",
    'import.matching.requiredField': "Campo obligatorio",
    'import.matching.optionalField': "Campo opcional",
    'import.matching.selectField': "Selecciona campo",

    'import.preview.field': "campo",
    'import.preview.all': 'Todos',
    'import.preview.error': 'Error',
    'import.preview.warnings': 'Actualitzados',
    'import.preview.ok': 'Importados',
    'import.preview.import': 'Importar',
    'import.preview.export': 'Exportar registros con errores',
    'import.preview.importDone': 'Importación finalizada',
    'import.preview.importAdded': " registros añadidos",
    'import.preview.importUpdated': " registros modificados",
    'import.preview.importError': " registros con errores",
    'import.preview.added': 'Importables',
    'import.preview.updated': 'Actualizables',
    'import.firstRowValues': "Valores en la primera fila",

    'import.subtotals.importing': 'Importando',
    'import.subtotals.added': 'Añadidos',
    'import.subtotals.updated': 'Actualitzados',
    'import.subtotals.error': 'Con errores',
    'import.subtotals.youHave': 'Tienes',
    'import.subtotals.pendingAlerts': 'No tienes alertas pendientes | Tienes {count} alerta pendiente | Tienes {count} alertas pendientes',
    'importablefield.code' : 'Código',
    'importablefield.name' : 'Nombre',
    'importablefield.area' : 'Área',

    'importablefield.parent': 'Módulo',
    'importablefield.study': 'Estudio',
    'importablefield.color': 'Color',

    "importablefield.stage_code" : "Código nivel",
    "importablefield.stage_name" : "Nombre nivel",
    "importablefield.stage_ieduca_id" : "Id nivel",
    "importablefield.study_code" : "Código estudio",
    "importablefield.study_name" : "Nombre estudio",
    "importablefield.study_ieduca_id" : "Id estudio",
    "importablefield.level_code" : "Código curso",
    "importablefield.level_name" : "Nombre curso",
    "importablefield.level_ieduca_id" : "Id curso",
    "importablefield.group_code" : "Código grupo",
    "importablefield.group_name" : "Nombre grupo",
    "importablefield.isBookable": "Reservable",
    "importablefield.isMeetingRoom": "Reunión",
    "importablefield.isClassroom": "Clase",
    "import.entity.esferastudent" : "alumnado Esfera",
    "importablefield.group": "Grupo clase",
    "importablefield.subjects": "Materias",
    "importablefield.stage": "Nivel",
    "importablefield.commerce_code": "Código línea de negocio",
    "importablefield.commerce_name": "Nombre línea de negocio",
    "import.entity.competence" : "Competencias",
    "importablefield.personFields": "Camps personalizados",
    "importablefield.code": "Código",
    "importablefield.name": "Nombre",
    "importablefield.bookable": "Reservable",
    "importablefield.meetingroom": "Sala de reuniones",
    "importablefield.area": "Departamento",
    "importablefield.stage_code": "Código nivel",
    // "importablefield.stage_name": "Nombre nivel", --> already defined
    "importablefield.stage_ieduca_id": "ID nivel",
    "importablefield.study_code": "Código estudio",
    "importablefield.study_name": "Nombre estudio",
    "importablefield.study_ieduca_id": "ID estudio",
    "importablefield.commerce_code": "Código línea de negocio",
    // "importablefield.commerce_name": "Nombre línea de negocio", --> already defined
    "importablefield.course_code": "Código curso",
    "importablefield.course_name": "Nombre curso",
    "importablefield.group_code": "Código grupo",
    "importablefield.group_name": "Nombre grupo",
    "importablefield.childName": "Nombre",
    "importablefield.childSurname1": "Apellido 1",
    "importablefield.childSurname2": "Apellido 2",
    "importablefield.childDni": "Documento de identidad",
    "importablefield.enrolmentCodeGroupCourseStage": "Código grupo matriculado",
    "importablefield.enrolmentNum": "Número de matricula",
    "importablefield.fatherId": "ID iEduca padre",
    "importablefield.fatherName": "Nombre padre",
    "importablefield.fatherSurname1": "Apellido 1 padre",
    "importablefield.fatherSurname2": "Apellido 2 padre",
    "importablefield.fatherDni": "Documento de identidad padre",
    "importablefield.fatherNif": "NIF padre",
    "importablefield.motherId": "ID iEduca madre",
    "importablefield.motherName": "Nombre madre",
    "importablefield.motherSurname1": "Apellido 1 madre",
    "importablefield.motherSurname2": "Apellido 2 madre",
    "importablefield.motherDni": "Documento de identidad madre",
    "importablefield.motherNif": "NIF madre",
    "importablefield.childBirthday": "Fecha nacimiento",
    "importablefield.childPlaceBirth": "Lugar nacimiento",
    "importablefield.childNationality": "Nacionalidad",
    "importablefield.childLang": "Idioma principal",
    "importablefield.childNia": "NIA",
    "importablefield.childNif": "NIF",
    "importablefield.childNumSS": "NSS",
    "importablefield.childNGender": "Sexo (H/M)",
    "importablefield.childStreet": "Dirección",
    "importablefield.childTown": "Población",
    "importablefield.childCountry": "País",
    "importablefield.childZip": "Código postal",
    "importablefield.childId": "ID iEduca",
    "importablefield.childGender": "Sexo (H/M)",
    "importablefield.contactChildEmail": "Email alumno",
    "importablefield.contactChildEmail1": "Email 1",
    "importablefield.contactChildEmail1Info": "Info email 1",
    "importablefield.contactChildEmail2": "Email 2",
    "importablefield.contactChildEmail2Info": "Info email 2",
    "importablefield.contactChildEmail3": "Email 3",
    "importablefield.contactChildEmail3Info": "Info email 3",
    "importablefield.contactChildPhone1": "Tel 1",
    "importablefield.contactChildPhone1Info": "Info tel 1",
    "importablefield.contactChildPhone2": "Tel 2",
    "importablefield.contactChildPhone2Info": "Info tel 2",
    "importablefield.contactChildPhone3": "Tel 3",
    "importablefield.contactChildPhone3Info": "Info tel 3",
    "importablefield.contactFatherEmail": "Email padre",
    "importablefield.contactFatherEmailInfo": "Info email padre",
    "importablefield.contactFatherPhone": "Tel padre",
    "importablefield.contactFatherPhoneInfo": "Info tel padre",
    "importablefield.contactMotherEmail": "Email madre",
    "importablefield.contactMotherEmailInfo": "Info email madre",
    "importablefield.contactMotherPhone": "Tel madre",
    "importablefield.contactMotherPhoneInfo": "Info tel madre",
    "importablefield.childProvince": "Provincia",
    "importablefield.teacherId": "ID iEduca",
    "importablefield.teacherName": "Nombre Profesor/a",
    "importablefield.teacherSurname1": "Apellido 1",
    "importablefield.teacherSurname2": "Apellido 2",
    "importablefield.teacherDni": "Documento de identidad",
    "importablefield.teacherGender": "Sexo (H/M)",
    "importablefield.teacherBirthday": "Fecha nacimiento",
    "importablefield.teacherStreet": "Dirección",
    "importablefield.teacherTown": "Población",
    "importablefield.teacherZip": "Código postal",
    "importablefield.contactTeacherPhone": "Teléfono",
    "importablefield.contactTeacherEmail": "Correo electrónico",
    "importablefield.teacherRole": "Rol",
    "importablefield.teacherGroup": "Grupo tutoria",
    "importablefield.contactPersonId": "ID iEduca",
    "importablefield.contactPersonDni": "Documento de identidad",
    "importablefield.contactPersonNia": "NIA",
    "importablefield.contactPersonNif": "NIF",
    "importablefield.contactValue": "Contacto",
    "importablefield.childTags": "Etiquetas",
    "importablefield.contactInfo": "Info",
    "importablefield.parent": "Módulo",
    "importablefield.study": "Estudio",
    "importablefield.color": "Color",
    "importablefield.id": "ID iEduca",
    "importablefield.surname1": "Apellido 1",
    "importablefield.surname2": "Apellido 2",
    "importablefield.birthday": "Data de nacimiento",
    "importablefield.placeBirth": "Lugar de nacimiento",
    "importablefield.nationality": "Nacionalidad",
    "importablefield.nia": "NIA",
    "importablefield.dni": "Documento de identidad",
    "importablefield.nif": "NIF",
    "importablefield.numSS": "Num. Seguridad Social",
    "importablefield.gender": "Sexo (H/M)",
    "importablefield.zip": "Código postal",
    "importablefield.town": "Población",
    "importablefield.country": "País",
    "importablefield.user.username": "Usuari",
    "importablefield.enrolments.completeName": "Grupos matriculados",
    "importablefield.personGroups.group.name": "Roles",
    "importablefield.tagPerson.tag.name": "Etiquetas",
    "importablefield.userPersonId": "ID",
    "importablefield.userPersonDni": "Documento de identidad",
    "importablefield.userUsername": "Usuario",
    "importablefield.userEmail": "Email",
    "importablefield.userPassword": "Contraseña",
    "importablefield.stage_type": "Tipus",
    "importablefield.responsible": "Responsable",
    "importablefield.dateEvent": "Fecha",
    "importablefield.slot.code": "Código franja horaria",
    "importablefield.slot.hourIni": "Hora inicio",
    "importablefield.slot.hourEnd": "Hora final",
    "importablefield.subject.name": "Materia",
    "importablefield.group.name": "Nombre grupo",
    "importablefield.group.code": "Código grupo",
    "importablefield.teachers.name": "Profesores",
    "importablefield.isClassroom": "Es aula",
    "importablefield.isBookable": "Reservable",
    "importablefield.isMeetingRoom": "Reuniones",
    "importablefield.subject.code": "Código materia",
    "importablefield.classGroup": "Grupo Clase",
    "importablefield.documentNumber": "Número de documento de identidad",
    "importablefield.documentType": "Tipo de documento de identidad",
    "importablefield.residenceTown": "Localidad de residencia",
    "importablefield.residenceProvince": "Provincia de residencia",
    "importablefield.contactEmail": "Correo electrónico",
    "importablefield.childStreetNumber": "Número",
    "importablefield.childIdentifyCode": "Identificador del alumno/a",
    "importablefield.contactChildPhone": "Teléfono",
    "importablefield.childAddressName": "Nombre vía",
    "importablefield.childAddressType": "Tipo de vía",
    "importablefield.childAddressBlock": "Bloque",
    "importablefield.childAddressPlant": "Planta",
    "importablefield.childAddressStair": "Escalera",
    "importablefield.childAddressDoor": "Puerta",
    "importablefield.childAddressInfo": "Resto de datos de la dirección",
    "importablefield.childEsferaEmail": "Contacto alumno - Correo electrónico",
    "importablefield.childEsferaPhone": "Contacto alumno - Teléfono",
    "importablefield.childEsferaSurname1": "Primer apellido",
    "importablefield.childEsferaSurname2": "Segundo apellido",
    "importablefield.tutor1Name": "Tutor 1 - nombre",
    "importablefield.tutor2Name": "Tutor 2 - nombre",
    "importablefield.tutor1Dni": "Tutor 1 - doc. identidad",
    "importablefield.tutor2Dni": "Tutor 2 - doc. identidad",
    "importablefield.tutor1Surname": "Tutor 1 - 1er apellido",
    "importablefield.tutor2Surname": "Tutor 2 - 1er apellido",
    "importablefield.group": "Grupo clase",
    "importablefield.subjects": "Materias",
    "importablefield.stage": "Nivel",
    "importablefield.course": "Curso",
    "importablefield.codeAmbit": "Código ámbito",
    "importablefield.codeDimension": "Código competencia",
    "importablefield.nameDimension": "Nombre competencia",
    "importablefield.codiCompetence": "Código criterio",
    "importablefield.nameCompetence": "Nombre criterio",
    "importablefield.subject.area.name": "Departamento",
    "importablefield.subject.parent": "Módulo",
    "importablefield.subject.study": "Estudio",
    "importablefield.subject.color": "Color",
    "importablefield.enrolmentDate": "Fecha matricula",
    "importablefield.payerNif": "NIF pagador",
    "importablefield.payerIban": "IBAN",
    "importablefield.payerPayMethod": "Forma pago",
    "importablefield.entryDate": "Fecha alta",
    "importablefield.fatherStreet": "Dirección padre",
    "importablefield.motherStreet": "Dirección madre",
    "importablefield.curriculum": "Curriculum",
    "importablefield.personFields": "Campos personalizados",
    "importablefield.recoverToken": "Enlace de recuperación",
    "importablefield.contacts": "Contactos",
    "importablefield.area.code": "Código Departamento",
    "importablefield.responsibleUser.person.dni": "DNI responsable",
    "importablefield.tutor1Surname2": "Tutor 1 - 2º apellido",
    "importablefield.tutor1DNI": "Tutor 1 - DNI",
    "importablefield.tutor1NIF": "Tutor 1 - NIF",
    "importablefield.tutor1Street": "Tutor 1 - Dirección",
    "importablefield.tutor1Contacts": "Tutor 1 - Contactos",
    "importablefield.tutor2Surname2": "Tutor 2 - 2º apellido",
    "importablefield.tutor2DNI": "Tutor 2 - DNI",
    "importablefield.tutor2NIF": "Tutor 2 - NIF",
    "importablefield.tutor2Street": "Tutor 2 - Dirección",
    "importablefield.tutor2Contacts": "Tutor 2 - Contactos",
    "importablefield.tags": "Etiquetas",
    "importablefield.street": "Dirección",
    "import.matching.personContactExist": "Persona con el contacto",
    "import.matching.personContactNew": "Persona  que se importa",
    "importablefield.contact": "Contacto",
    "importablefield.password": "Contraseña",
    "importablefield.evaluabletype_value": "Nota vinculada",
    "importablefield.identifier": "Identificador",
    "importablefield.courseStagesCodes": "Códigos cursos",
    "importablefield.center_code": "Código centro",
    "importablefield.year_code": "Código año",
    "importablefield.program_id": "ID programación",
    "importablefield.program_description": "Descripción programación",
    // "importablefield.commerce_name": "Nombre línea de negocio", --> already defined
    // "importablefield.stage_name": "Nombre nivel", --> already defined
    "importablefield.coursestage_name": "Nombre curso",
    "importablefield.subject_name": "Nombre materia",
    "importablefield.content_id": "ID",
    "importablefield.content_parent_id": "ID contenido padre",
    "importablefield.content_parent_title": "Título contenido padre",
    "importablefield.content_type": "Tipo",
    "importablefield.content_title": "Título",
    "importablefield.content_description": "Descripción",
    "importablefield.convocatory_code": "Código convocatoria",
    "importablefield.competence_code": "Código competencia",
}
